.manufacturers{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    .manufacturer{
        position: relative;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
        align-items: stretch;
        background-color: #f8f8f8;

        .logo{
            width: 150px;
            height: 150px;
            margin:30px auto;
            img {
                border-radius: 50%;
            }
        }
        .details-cont{
            padding: 0px 20px 30px 20px;

            & > a {
                display:flex;
                flex-direction: column;
                text-align: center;
            }
            .name{
                color: #333333;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: normal;
                line-height: 18px;
                text-align: left;
                /* Text style for "3M Company" */
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                margin-bottom: 10px;
                min-height: 50px;
            }
            .details{
                /* Style for "Székhely:" */
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                line-height: 18px;
                text-align: left;
                margin-bottom: 20px;
                &.details-upper {
                    min-height: 55px;
                }
                span{
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                }
            }
        }
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        }


    }
    & > div.columns {
        margin-top: 20px;
    }
}
.manufacturer-section{
    padding:80px 0;
    .product-image-container {
        background: #F8F8F8;
        padding: 50px;
        display: flex;
        justify-content: center;
        img {
            border-radius: 50%;
        }
    }
}
.manufacturer-page{
    h1{
        color: #333333;
        font-size: 30px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 27px !important;
        text-align: left;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        padding-bottom:10px;
    }
    .details-container{
        padding-top:10px;
        border-top:2px solid #e6e6e6;
        span{
            font-weight: 700;
        }
        .details{
            margin-bottom: 30px;
        }
    }
}

.manufacturer-title-container{
    .headline{
        display:block;
        margin: 0 auto;
        border: 0;
        text-align: center;
    }
    .line{
        border-bottom: 2px solid #00a3c8;
        display: block;
        margin: 0 auto;
        width: 50px;
        height: 2px;
    }
}

.manufacturer-logo-container {
    background-color: #f8f8f8;
    padding: 20px;
}

.no-carousel {
    display: flex;
    justify-content: space-around;
    padding:30px 0px;
    .manufacturer-logo-container {
        @include screen-sm-up {
            max-width: 190px;
        }
    }
}

.manufacturer-page {
    ul {
        list-style: inherit;
        margin-left: 30px;
    }
    ul, ol {
        margin-bottom: 15px;
    }
}