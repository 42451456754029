//social share
.social-share-section {
  .social-share-view {
    list-style: none;
    display: inline;
    padding-left: 0;
    li {
      display: inline-block;
      a {
        padding: 15px;
        &.facebook-share {
          color: #4267B2;
          padding-left: 0;
        }
        &.viber-share {
          color: #7360f2;
        }
        &.email-share {
          color: lighten($tisztaenergia-dark, 20%);
          padding-right: 0 !important;
        }
        &.messenger-share {
          color: #0078ff;
        }
        i {
          font-size: 32px;
        }
      }
    }
  }
}
