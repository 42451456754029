.banner-container{
    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background: $tisztaenergia-main !important;
    }
    .large-banner-owl-container {
        .owl-stage-outer {
            padding-top: 0;
            @media (max-width: 991px) {
                padding-bottom: 0;
            }
            @media (min-width: 1389px) {
                padding-bottom: 0;
            }
        }
        .owl-nav button {
            width: 50px !important;
            height: 50px !important;
            display: block !important;
            background-color: #fff !important;
            border-radius: 25px !important;
            top: 11.5px;
        }
        .owl-dots {
            @media (max-width: 991px) {
                margin-top: 16px !important;
                margin-bottom: 10px;
            }
            @media (max-width: 768px) {
                margin-top: 10px !important;
            }
        }
    }
    .large-banner-container {
        background-color: $white;

        .large-banner-image-container {
            min-height: 400px;
            display: block;
            background-position: right center;
            background-repeat: no-repeat;

            .large-banner-text {
                padding-left: 40px;
                padding-top: 100px;
                padding-right: 40px;
                width: 390px;
                float: left;
                background-color: $white;
                min-height: 400px;
                -webkit-clip-path: polygon(0 0, 85% 0%, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 85% 0%, 100% 100%, 0% 100%);

                .banner-sub-title {
                    text-transform: uppercase;
                    color: $tisztaenergia-light-blue;
                    font-size: 16px;
                }

                .banner-title {
                    font-size: 44px;
                    color: #333333;
                    font-weight: 700;
                    line-height: 40px;
                    a{
                        color: $tisztaenergia-light-blue;
                        &:hover{
                            color: $tisztaenergia-light-blue;
                        }
                    }
                }

                .banner-description {
                    color: #757575;
                    font-size: 16px;
                    a{
                        color: #757575;
                        &:hover{
                            color: #757575;
                        }
                    }
                }

                .banner-cta {
                    display: inline-block;
                    padding: 7px 14px 7px 11px;
                    font-size: 13px;
                    font-weight: 600;
                    border: 2px solid $tisztaenergia-main-orange;
                    background-color: $white;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    -webkit-transition: background-color 0.2s ease-in-out;
                    -moz-transition: background-color 0.2s ease-in-out;
                    -o-transition: background-color 0.2s ease-in-out;
                    -ms-transition: background-color 0.2s ease-in-out;
                    transition: background-color 0.2s ease-in-out;
                    color: $tisztaenergia-main-orange;

                    span {
                        font-size: 17px;
                    }

                    &:hover {
                        background-color: $white;
                        border: 2px solid $tisztaenergia-main-yellow;
                        color:$tisztaenergia-main-yellow;
                        padding: 7px 14px 7px 11px;
                    }
                }

                @media only screen and (min-width: 991px) and (max-width: 1389px) {
                    width: 320px;
                    .banner-sub-title {
                        font-size: 14px;
                    }
                    .banner-title {
                        font-size: 30px;
                    }
                    .banner-description {
                        font-size: 14px;
                    }
                    .banner-cta {
                        font-size: 12px;
                    }
                }
            }
        }

        @media only screen and (min-width: 1389px) {
            margin-bottom: 20px;
        }
        @media only screen and (max-width: 1389px) {
            margin-bottom: 5px;
        }
        @media (max-width: 768px) {
            .banner-cta {
                font-size: 14px !important;
            }
        }
    }

    .small-banner-container {
        background-color: $white;
        min-height: 190px;

        .small-banner-image-container {
            min-height: 190px;
            display: block;
            background-position: right center;
            background-repeat: no-repeat;

            .small-banner-text {
                padding-left: 20px;
                padding-top: 50px;
                width: 170px;
                float: left;
                background-color: $white;
                min-height: 190px;

                .banner-sub-title {
                    text-transform: uppercase;
                    color: $tisztaenergia-light-blue;
                    font-size: 16px;
                    line-height: 16px;
                    margin-bottom: 5px;
                }

                .banner-title {
                    font-size: 24px;
                    color: $dark-grey2;
                    font-weight: 700;
                    a{
                        color: $dark-grey2;
                        &:hover{
                            color: $dark-grey2;
                        }
                    }
                }

                .banner-description {
                    color: #999999;
                    font-size: 16px;
                    a{
                        color: #999999;
                        &:hover{
                            color: #999999;
                        }
                    }
                }

                .banner-cta {
                    display: inline-block;
                    font-size: 16px;
                    margin-bottom: 5px;
                    -webkit-transition: background-color 0.2s ease-in-out;
                    -moz-transition: background-color 0.2s ease-in-out;
                    -o-transition: background-color 0.2s ease-in-out;
                    -ms-transition: background-color 0.2s ease-in-out;
                    transition: background-color 0.2s ease-in-out;

                    span {
                        font-size: 17px;
                    }
                    color: $tisztaenergia-main-orange;
                    &:hover {
                        color: $tisztaenergia-main-yellow;
                    }
                }

                @media only screen and (min-width: 991px) and (max-width: 1389px) {
                    width: 100px;
                    .banner-sub-title {
                        font-size: 14px;
                    }
                    .banner-title {
                        font-size: 16px;
                    }
                    .banner-description {
                        font-size: 12px;
                    }
                    .banner-cta {
                        font-size: 12px;
                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
        .eleven.columns{
            width: calc(100% - 20px);
        }
        .five.columns{
            width: calc(100% - 20px);
            .small-banner-container {
                width: calc(50% - 10px);
                float: left;
                &.first{
                    margin-right: 20px;
                }
            }

        }
    }
    @media only screen and (min-width: 1390px){
        .eleven {
            &.columns{
                width:770px;
            }
        }
        .five {
            &.columns{
                width:370px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .large-banner-container {
            background-color: $white;
            min-height: 190px;


            .large-banner-image-container {
                min-height: 190px;
                display:block;
                background-position: right center;
                background-repeat: no-repeat;

                .large-banner-text {
                    padding-left: 20px;
                    padding-top: 50px;
                    width: 180px;
                    float: left;
                    background-color: $white;
                    min-height: 190px;
                    -webkit-clip-path: none;
                    clip-path: none;

                    .banner-sub-title {
                        text-transform: uppercase;
                        color: $tisztaenergia-dark-blue;
                        font-size: 16px;
                        line-height: 16px;
                        margin-bottom: 5px;
                    }

                    .banner-title {
                        font-size: 24px;
                        color: $dark-grey2;
                        font-weight: 700;
                        line-height: 24px;
                        a{
                            color: $dark-grey2;
                            &:hover{
                                color: $dark-grey2;
                            }
                        }
                    }

                    .banner-description {
                        display: none;
                    }

                    .banner-cta {
                        display: inline-block;
                        font-size: 16px;
                        margin-bottom: 5px;
                        -webkit-transition: background-color 0.2s ease-in-out;
                        -moz-transition: background-color 0.2s ease-in-out;
                        -o-transition: background-color 0.2s ease-in-out;
                        -ms-transition: background-color 0.2s ease-in-out;
                        transition: background-color 0.2s ease-in-out;
                        border: 0;
                        text-transform: capitalize;
                        padding: 0;
                        background-color: transparent;
                        span {
                            font-size: 17px;
                        }
                        color: $tisztaenergia-main-orange;
                        &:hover {
                            color: $tisztaenergia-main-yellow;
                            padding: 0;
                            border: 0;
                            background-color: transparent;
                        }
                    }

                    @media only screen and (min-width: 991px) and (max-width: 1389px) {
                        width: 320px;
                        .banner-sub-title {
                            font-size: 14px;
                        }
                        .banner-title {
                            font-size: 30px;
                        }
                        .banner-description {
                            font-size: 14px;
                        }
                        .banner-cta {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}