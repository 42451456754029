$tisztaenergia-main : #284259;
$tisztaenergia-dark : #161616;
$tisztaenergia-light : lighten(#3B3C41, 50%);
$tisztaenergia-lighter : #cad9e7;
$backgroundColor: #e0e4e5;
$dark-blue: #273678;
$menuwrapper-bottom: #CECECE;
$light-blue: #00a3c8;
$light-blue-hover: #0096c8;
$dark-green: #0ac781;
$tisztaenergia-blog-text : #575757;

$topbar-blue: #277840;

$tisztaenergia-light-blue: #60aee1;
$tisztaenergia-dark-blue: #273678;
$tisztaenergia-main-yellow: #fdd200;
$tisztaenergia-main-orange: #eb8115;

$white: #fff;
$black: #000;
$background: #e0e4e5;
$grey: #777;
$dark-grey: #1A1A1A;
$dark-grey2: #333333;
$light-grey: #e9e9e9;
$light-pink: #FED1DB;
$light-green: #D1FEDB;
$light-pink-secondary: #EFC5CE;
$light-green-secondary: #C5EFCE;
$green: #6ca405;
$red: #d11c1c;
$checkbox-background: #f6f6f6;
$checkbox-border: #eeeeee;

$blue: #2db2ea;
$orange: #fa5b0f;
$navy: #495d7f;
$yellow: #ffb400;
$peach: #fcab55;
$red2: #dd3737;
$beige: #bfa980;
$pink: #d64391;
$cyan: #37b6bd;
$celadon: #74aea1;
$brown: #784e3d;
$cherry: #911938;
$purple: #917ae9;

$evernote: #6bb130;
$flickr: #136dd5;
$rss: #fe8f00;
$youtube: #bf2f29;
$vimeo: #4c8ab0;
$xing: #036568;
$yahoo: #9251a2;
$amazon: #2f3842;
$steam: #383838;
$dropbox: #2c92dd;
$github: #5c7381;
$lastfm: #d21309;
$tumblr: #3d658d;
$instagram: #3f6f94;
$twitter: #3bc1ed;
$dribble: #ef5a92;
$facebook: #4a6d9d;
$linkedin: #0b7bb5;
$delicious: #3274d1;
$paypal: #2e689a;
$gplus: #d53824;
$stumbleupon: #eb4924;
$pinterest: #cb1f25;
$forrst: #40833e;
$digg: #205685;
$reddit: #404040;
$blogger: #ff6501;

$success-bg: #EBF6E0;
$success: #5f9025;
$error-bg: #ffe9e9;
$error: #de5959;
$warning-bg: #FBFADD;
$warning: #8f872e;
$notice-bg: #E9F7FE;
$notice: #5091b2;

$contact-border: #ffeceb;
$contact-label: #e24747;
$content-box-hover: #0e83cd;

$input-color: #1A1A1A;
$input-bg-disabled: #888;


// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  960px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;