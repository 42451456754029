.solarpanel-calculator {
  form {
    margin-bottom: 10px;
  }
  h1 {
    border-bottom: 2px solid #eb8115;
  }
  &-main-image {
    margin-bottom: 20px;
  }
  .solarpanel-step-item {
    background-color: $white;
    padding: 30px 20px 20px 20px;
    margin-bottom: 20px;
    h1 {
    }
    h2 {
      font-size: 20px;
      color: #333333;
      font-weight: 700;
      margin-bottom: 30px;
      display: flex;
      p {
        &.step-number {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          font-size: 16px;
          color: $white;
          margin-right: 10px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #eb8115;
        }
      }
    }
    &-description {
      margin-bottom: 20px;
      color: #6f6f6f;
      font-size: 15px;
    }
    &-orientation {
      width: calc(50% - 2px);
      &-images {
        margin-bottom: 25px;
        img {
          max-width: unset !important;
        }
      }
    }
    &-pitch {
      width: calc(50% - 17px);
      margin-left: 15px;
      &-images {
        margin-bottom: 30px;
      }
    }
    &-orientation, &-pitch {
      overflow: hidden;
      display: inline-block;
      .form-row-wide {
        width: 100% !important;
        display: inline-block;
        margin-right: 0 !important;
      }
      &-images {
        height: 261px;
        overflow: hidden;
        img {
          width: auto;
          &.orientation-position, &.solarpanel-pitch {
            margin: 0 auto 20px;
          }
          &.orientation-ruler {
            transition: 0.6s ease-in-out;
          }
        }
      }

    }
    &-result-content {
      width: 24%;
      &.no-inverter {
        width: 33%;
      }
      display: inline-block;
      text-align: center;
      border-right: 1px solid #ccc;
      &:last-of-type {
        border-right: none;
      }
      sup {
        vertical-align: super !important;
        font-size: smaller !important;
      }
      p {
        color: #333333;
        font-weight: 700;
        span {
          font-size: 24px;
        }
        &.small {
          color: #6f6f6f;
          font-weight: 400;
          font-size: 16px;
        }
      }
      &-image {
        margin: 0 auto;
        margin-bottom: 30px;
        height: 55px !important;
      }
    }
    &:not(.no-grid) {
      .form-row {
        &.form-row-wide {
          width: calc(50% - 17px);
          display: inline-block;
          &:first-of-type {
            margin-right: 30px;
          }
        }
      }
    }
    .form-row {
      &.form-row-wide {
        label {
          font-weight: 700;
        }
        select, input {
          background-color: #f0f5f8;
          border: 1px solid #f0f5f8;
        }
        .help-block {
          display: inline-block;
        }
        #calculatorform-roof_type {
          display: flex;
          label {
            cursor: pointer;
            &:nth-child(2){
              margin: 0 20px;
            }
            &:hover, &:focus, &:active, &.active {
              border: 2px solid #eb8115;
              background-color: #fef9f3;
            }
            img {
              margin-bottom: 20px;
            }
            width: 33%;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid #d0dbe2;
            background-color: #f0f5f8;
            padding: 60px 70px 40px 70px;
            input {
              display: none;
            }
          }
        }
      }
    }
  }
  .solar-faq {
    background-color: $white;
    padding: 30px 20px;
    h1 {
      font-size: 20px;
      text-transform: initial;
      font-weight: 700;
      line-height: 24px;
      border-bottom: none;
      margin-bottom: 30px;
    }
    .trigger {
      &:last-child a {
        margin-bottom: 0;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        margin: 0;
        margin-bottom: 20px;
        border: 1px solid #ebebeb;
        padding: 25px 20px;
        i {
          transition: 0.3s ease-in-out;
          padding-left: 15px;
        }
      }
      &.active {
        a {
          color: #eb8115;
          border-color: #eb8115;
          &:hover, &:focus, &:active {
            background-color: transparent;
          }
          i {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .solarpanel-container {
    .one-third.column, .two-thirds.column {
      width: calc(100% - 20px);
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
}
@media (max-width: 1389px) {
  .solarpanel-calculator {
    .solarpanel-step-item {
      &-result-content {
        &.no-inverter {
          width: 32% !important;
        }
      }
      &-pitch {
        &-images {
          img {
            //max-width: 100% !important;
          }
        }
      }
    }
  }
}
@media (max-width: 990px) {
  .solarpanel-calculator {
    .solar-faq {
      margin-bottom: 30px;
    }
    .solarpanel-step-item {
      #calculatorform-roof_type {
        label {
          padding: 40px !important;
        }
      }
    }
  }
}
@media (min-width: 991px) and (max-width: 1389px) {
  .solarpanel-calculator {
    .solarpanel-step-item {
      &-pitch {
        &-images {
          height: 247px !important;
        }
      }
      #calculatorform-roof_type {
        label {
          padding: 40px !important;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .solarpanel-calculator {
    .solarpanel-step-item {
      &-pitch, &-orientation {
        width: 100% !important;
      }
      &-pitch {
        margin-left: 0 !important;
      }
      h2 {
        flex-direction: column;
        align-items: center;
        margin-bottom: 55px !important;
        p {
          &.step-number {
            width: 45px !important;
            height: 45px !important;
            margin-right: 0 !important;
            margin-bottom: 25px;
          }
        }
        span {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 625px) {
  .solarpanel-calculator {
    .solarpanel-step-item {
      #calculatorform-roof_type {
        flex-direction: column;
        align-items: center;
        label {
          width: 50% !important;
          margin-bottom: 25px !important;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .solarpanel-calculator {
    .solarpanel-step-item {
      &-result-content {
        width: 100% !important;
        display: block !important;
        margin: 0 auto !important;
        border-right: none !important;
        margin-bottom: 45px !important;
      }
      &:not(.no-grid) {
        .form-row {
          &.form-row-wide {
            width: 100% !important;
            display: block !important;
            &:first-of-type {
              margin-right: 0 !important;
            }
          }
        }
      }
      #calculatorform-roof_type {
        label {
          width: 100% !important;
        }
      }
    }
  }
}