i.button,
a.button,
input[type="button"], input[type="submit"],
button{
    cursor: pointer;
    padding: 7px 14px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    outline: none;
    overflow: hidden;
    position: relative;
    z-index: 10;
    color: $white;
    background-color: $tisztaenergia-main-orange;
    border: none;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;

    &.create-billing,
    &.create-shipping{
        padding: 5px 12px;
    }
}

i.button,
a.button,
button {
    padding: 13px 14px;
    line-height: 24px;
    &:hover{
        background-color: $tisztaenergia-main-yellow;
        color: $white;
    }
}
input {
    &[type="button"], &[type="submit"] {
        padding: 10px 14px;
    }
    &[type="button"]:hover, &[type="submit"]:hover {
        background-color: lighten($grey, 22%);
    }
}

i.button,
a.button {
    &.dark {
        background-color: $tisztaenergia-dark;
    }
    &.gray, &.colo, &.dark:hover, &.gray:hover, &.color:hover {
        background-color: #e0e4e5;
        color:$dark-grey2;
    }
}

.button i {
    margin-right: 3px;
}

i,
a{
    &.button{
        &.fb{
            background-color: $facebook;
            color: $white;
            &:hover{
                background-color: darken($facebook, 10%);
            }
            .fbicon{
                font-size: 28px;
                margin-right: 20px;
                margin-top: -5px;
                &::before{
                    vertical-align: sub;
                }
            }
        }
    }
}
.button {
    &.w100 {
        width: 100% !important;
        text-align: center;
    }
}