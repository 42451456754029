/* ------------------------------------------------------------------- */
/* Pink $light-pink
---------------------------------------------------------------------- */
.top-bar-dropdown ul li a:hover,
.skill-bar-value,
.counter-box.colored,
.pagination .current,
.pagination ul li a:hover,
.pagination-next-prev ul li a:hover,
.tabs-nav li.active a,
.dropcap.full,
.highlight.color,
.ui-accordion .ui-accordion-header-active:hover,
.ui-accordion .ui-accordion-header-active,
.trigger.active a,
.trigger.active a:hover,
.share-buttons ul li:first-child a,
a.caption-btn:hover,
.mfp-close:hover,
.mfp-arrow:hover,
.img-caption:hover figcaption,
#price-range .ui-state-default,
.selectricItems li:hover,
.product-categories .img-caption:hover figcaption,
.rsDefault .rsThumbsArrow:hover,
.customSelect .selectList dd.hovered,
.qtyplus:hover,
.qtyminus:hover,
a.calculate-shipping:hover,
.og-close:hover,
.tags a:hover {
    background: $tisztaenergia-main-yellow;
    color:$dark-grey2;
}


.top-search button:hover,
.cart-buttons a,
.cart-buttons a.checkout,
.menu > li:hover .current,
.menu > li.sfHover .current,
.menu > li:hover,
.menu > li.sfHover,
li.dropdown ul li a:hover,
input[type="button"],
input[type="submit"],
i.button,
a.button,
i.button.color,
a.button.color,
i.button.dark:hover,
a.button.dark:hover,
i.button.gray:hover,
a.button.gray:hover,
.icon-box:hover span,
.tp-leftarrow:hover,
.tp-rightarrow:hover,
.sb-navigation-left:hover,
.sb-navigation-right:hover,
.product-discount,
.newsletter-btn,
#categories li a:hover,
#categories li a.active,
.flexslider .flex-prev:hover,
.flexslider .flex-next:hover,
.rsDefault .rsArrowIcn:hover,
.hover-icon,
#backtotop a:hover,
#filters a:hover,
#filters a.selected {
    background-color: $tisztaenergia-main-orange;
    color:$white;
}
#jPanelMenu-menu li a:hover{
    color:$light-pink;
}



a {
    color: $tisztaenergia-dark-blue;
}

.happy-clients-author,
#categories li li a.active span,
#categories li li a.active,
.additional-menu ul li a:hover,
.additional-menu ul li a:hover span,
.mega a:hover,
.mega ul li p a,
#not-found i,
.dropcap,
.list-1.color li:before,
.list-2.color li:before,
.list-3.color li:before,
.list-4.color li:before,
.comment-by span.reply a:hover,
.comment-by span.reply a:hover i,
#categories li ul li a:hover span,
#categories li ul li a:hover,
table .cart-title a:hover,
.cart .st-val a:hover,
.st-val a:hover,
.meta a:hover {
    color: $tisztaenergia-dark-blue;
}

@include screen-xs{
    .menu > li.curr{
        background-color: $tisztaenergia-main-yellow;
        margin-top: -1px;
        a.current{
            color:$dark-grey;
            border-top: 1px solid $light-grey;
        }
    }
}

.current-page { background-color: $light-pink !important; }
blockquote { border-left: 4px solid $light-pink; }
.categories li a:hover { color: $light-pink !important; }