@import "core/variables";
@import "core/mixins";
@import "core/helpers";
@import "core/buttons";
@import "core/style";

@import "blocks/addresses";
@import "blocks/banners";

@import "blocks/index";

@import "blocks/mfp";
@import "blocks/popup";
@import "blocks/product";
@import "blocks/loading";
@import "blocks/checkout";
@import "blocks/oireset";
@import "blocks/package-receiving";
@import "blocks/rtl";
@import "blocks/thank-you-page";
@import "blocks/hero-section";
@import "blocks/social-share";
@import "blocks/faq-page";
@import "blocks/nor";
@import "blocks/manufacturer";
@import "blocks/solarpanel-calculator";
@import "blocks/user-purchase";

@import "core/collapse";



@import "color";

@import "blog/main";


*{
    box-sizing: border-box;
}
.margin-right-0{
    margin-right: 0px !important;
}
.yii-debug-toolbar__block {
    &.yii-debug-toolbar__title{
        img{
            width: 30px !important;
            height: 30px !important;
        }
    }
}
.menu-wrapper{
    background: $white;
}

#logo {
    margin-top: 25px;
    a {
        img{
            height: 55px;
        }
    }
}

.form-row{
    margin-bottom: 15px;
    &.hidden-input{
        display: none;
    }
    &.has-error{
        .help-block{
            color: $red;
        }
    }
}
.sweet-alert{
    top: 33% !important;
    button{
        border-radius: 0 !important;
        box-shadow: none !important;
        font-size: 13px !important;
        font-weight: bold !important;
    }
}

.showbiz-container *{
    box-sizing: initial;
}

.light-banner {
    background-color: rgba(0,0,0, 0.7);
    color: #fff !important;
    margin-bottom: 15px;
    padding: 15px !important;
}

.dark-banner {
    background-color: rgba(255,255,255, 0.7);
    color: #000 !important;
    margin-bottom: 15px;
    padding: 15px !important;
}

.oldalinfo-page {
    b {
        font-weight: 700 !important;
    }
}

section.bg-white{
    background-color: $white;
}

#jPanelMenu-menu li li a {
    background-color: #ffffff;
}

#jPanelMenu-menu .dropdown ul {
    position: relative;
}
.menu-logo-virus {
    display: flex;
    justify-content: center;
    .mobile-menu-logo {
        height: 30px;
    }
}

#jPanelMenu-menu {
    .mobile-menu-jpanel {

        &:hover, &:focus, &:active {
            background-color: $tisztaenergia-dark-blue !important;
        }
    }
}

@include screen-xs {
    .sa-icon {
        display:none !important;
    }
}

.notification-input{
    font-size: 14px;
    line-height: 23px;
    position: relative;
    padding: 13px 18px 13px 18px;
    &.notice{
        background-color: #E9F7FE;
        border: 1px solid darken(#E9F7FE, 10%);
    }
}
.tel-input-phone-input-wrapper {
    .iti__country-list{
        z-index: 11 !important;
    }
}

.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}

.product-dedicatory-widget {
    margin-top: 30px;
}