.user-purchase-view {
  &-title {
    h3 {
      float: right;
    }
  }
  &-stepper {
    margin: 40px 0 80px 0;

    .stepper {
      list-style: none;
      display: flex;
      flex-direction: row;
      padding: 0;

      .step {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.active {
          .dot {
            background-color: $tisztaenergia-main-orange;
            span {
              color: #fff;
            }
          }

          .lower-text {
            color: #3b3c41;
            .dot-icon {
             filter: opacity(100%);
            }
          }

          &:after {
            background-color: $tisztaenergia-main-orange;
          }
        }

        &:last-child {
          &:after {
            width: 50%;
            transform: translateX(-50%)
          }
        }

        &:first-child {
          &:after {
            width: 50%;
            transform: translateX(50%)
          }
        }

        &:after {
          content: "";
          width: 100%;
          height: 3px;
          position: absolute;
          top: 25px;
          background-color: $backgroundColor;
          z-index: 0;
        }

        .upper-text {
          font-size: 11px;
          line-height: 1.5;
          text-align: center;
          color: #999;
          height: 20px;
          display: block;
          span {
            font-size: 20px;
          }
        }

        .lower-text {
          font-size: 18px;
          text-transform: uppercase;
          line-height: 1.57;
          text-align: center;
          color: #999;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 32px;
        }

        .dot {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $backgroundColor;
          span {
            color: #000;
          }
          &-icon {
            margin-right: 16px;
            width: 56px;
            filter: opacity(50%);
          }
        }
      }
    }
  }

  &-details {
    .container {
      display: flex;
      .columns {
        display: flex;
      }
    }
    &-box {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid #e5e9e8;
      padding: 16px;
      &:hover {
        .box-icon {
          color: rgba(235, 129, 21, 1);
        }
      }
      .box-icon {
        top: 13px;
        right: 13px;
        position: absolute;
        font-size: 34px;
        transition: .2s ease-in-out;
        color: rgba(235, 129, 21, .2);
      }
      h3 {
        margin-bottom: 16px;
        text-transform: uppercase;
        font-size: 18px;
        &.divided-detail-title {
          margin-top: 32px;
        }
      }
      div {
        span {
          font-weight: 700;
          font-size: 12px;
          text-transform: uppercase;
        }
        p {
          line-height: 16px;
          margin-bottom: 8px;
        }
        a {
          margin-top: 12px;
        }
      }

    }
  }
  .cart-table {
    .flex-td {
      display: flex;
      align-items: center;
    }
    .user-purchase-product-image {
      display: inline-flex;
      margin-right: 16px;
    }
    .button {
      width: 100%;
      text-align: center;
    }
  }

  .status-icon-container {
    display: flex;
    justify-content: center;
    margin: 10px 0 50px 0;
    img {
      margin-left: 24px;
      height: 70px !important;
    }
  }
  .waiting-for-payment-section {
    margin-bottom: 50px;
  }
}

.clickable-table-row {
  cursor: pointer;
  &.with-hover {
    transition: .1s ease-in-out;
    &:hover {
      background: $tisztaenergia-main-orange;
      color: #fff;
    }
  }
}
.lightened-table-header {
  th {
    background-color: #f5f5f5 !important;
  }
}