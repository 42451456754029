.solarpanel-cta-block {
  display: flex;
  height: 270px;
  padding: 30px;
  background-size: cover;
  @include screen-xs {
    margin-bottom: 20px;
  }
  &.solarpanel-calculator {
    text-align: right;
    align-items: flex-end;
    align-content: flex-end;
    @include screen-xs {
      text-align: left;
    }
    background-image: url("/images/solarpanel-calculator/solar_1.jpg");
  }
  &.solarpanel-offers {
    text-align: left;
    align-items: flex-end;
    align-content: flex-start;
    background-image: url("/images/solarpanel-calculator/solar_2.jpg");
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
    color: $white;
    line-height: 24px;
    padding-bottom: 20px;
  }
  p {
    font-size: 14px;
    padding-bototm: 20px;
    line-height: 20px;
    color: $white;
  }
  .button {
    &.cta-button {
      padding: 15px 20px;
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
      line-height: normal;
      transition: 0.3s ease-in-out;
      &:hover, &:focus, &:active {
        background-color: $white;
        color: #333333;
      }
    }
  }
}